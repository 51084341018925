<template>
  <div class="bg-light rounded-3 p-4 p-md-5 mb-3">
    <!-- Title -->
    <h2 class="h4 mb-5 text-center">
      <!--      <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
      Please provide a few details
    </h2>
    <!-- / Title -->

    <!-- Job Title -->
    <div class="mb-5">
      <label class="form-label" for="jobTitle">Job Title</label>
      <input v-model="job_title" type="text" class="form-control"
             placeholder="Firstly pop in a title, for example 'Advice on retrospective PR219 return'"
             id="jobTitle"/>
    </div>
    <!-- / Job Title -->

    <!-- Description -->
    <div class="mb-5">
      <label class="form-label" for="jobDescription">Job Description</label>
      <textarea v-model="job_description"
                placeholder="Now put a bit more detail to help your lawyers with the context, including whether your client prefers fixed or variable fees"
                class="form-control" id="jobDescription" rows="5"></textarea>
    </div>
    <!-- / Description -->

    <!-- Should Invite Client -->
    <div v-if="!isClient" class="mb-4">
      <div class="form-check form-check-inline">
        <input v-model="should_invite_client"
               value="personal"
               class="form-check-input" id="shouldInviteClient" type="checkbox" name="shouldInviteClient">
        <label class="form-check-label" for="shouldInviteClient">Invite the client at this stage?</label>
      </div>
    </div>
    <!-- / Should Invite Client -->

    <!-- Client Details -->
    <div v-if="!isClient && shouldInviteClient">
      <!-- Client Name -->
      <div class="mb-5">
        <label class="form-label" for="clientName">Client Name</label>
        <input v-model="client_name" type="text" class="form-control"
               placeholder="Next put in the name of your client"
               id="clientName"/>
      </div>
      <!-- / Client Name -->

      <!-- Client Email -->
      <div class="">
        <label class="form-label" for="clientEmail">Client Email</label>
        <input v-model="client_email" type="email"
               placeholder="And lastly add your client's email address"
               class="form-control" id="clientEmail"/>
      </div>
      <!-- / Client Email -->
    </div>
    <!-- / Client Details -->

    <!-- Footer Navigation -->
    <div class="wizard-footer-navigation">
      <!-- Previous -->
      <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"
         @click.prevent="handlePreviousButton">
        <i class="fi-chevron-left fs-sm me-2"></i>
        Previous
      </a>
      <!-- / Previous -->

      <!-- Next -->
      <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"
         @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
        Next
        <i class="fi-chevron-right fs-sm ms-2"></i>
      </a>
      <!-- / Next -->
    </div>
    <!-- / Footer Navigation -->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProvideJobDetails",
  computed: {
    ...mapGetters('findALawyer', {
      jobTitle: 'jobTitle',
      jobDescription: 'jobDescription',
      shouldInviteClient: 'shouldInviteClient',
      clientName: 'clientName',
      clientEmail: 'clientEmail'
    }),

    ...mapGetters('auth', {
      isClient: 'isClient'
    }),

    canGoForward() {
      let canGoForward = (this.jobTitle && this.jobTitle.trim())
          && (this.jobDescription && this.jobDescription.trim());

      if (this.shouldInviteClient) {
        canGoForward = canGoForward
            && (this.clientName && this.clientName.trim())
            && (this.clientEmail && this.clientEmail.trim());
      }

      return canGoForward;
    },

    job_title: {
      get() {
        return this.jobTitle;
      },
      set(val) {
        this.setJobTitle(val);
      }
    },

    job_description: {
      get() {
        return this.jobDescription;
      },
      set(val) {
        this.setJobDescription(val);
      }
    },

    should_invite_client: {
      get() {
        return this.shouldInviteClient;
      },
      set(val) {
        this.setShouldInviteClient(val);
      }
    },

    client_name: {
      get() {
        return this.clientName;
      },
      set(val) {
        this.setClientName(val);
      }
    },

    client_email: {
      get() {
        return this.clientEmail;
      },
      set(val) {
        this.setClientEmail(val);
      }
    }
  },
  created() {
    if (this.isClient) {
      this.setShouldInviteClient(false);
    }
  },
  methods: {
    ...mapActions('findALawyer', {
      decrementWizardStage: 'decrementWizardStage',
      incrementWizardStage: 'incrementWizardStage',
      setJobTitle: 'setJobTitle',
      setJobDescription: 'setJobDescription',
      setShouldInviteClient: 'setShouldInviteClient',
      setClientName: 'setClientName',
      setClientEmail: 'setClientEmail'
    }),

    handlePreviousButton() {
      this.decrementWizardStage();
    },

    handleNextButton() {
      if (!this.canGoForward) {
        return false;
      }
      this.incrementWizardStage();
    }
  },
}
</script>

<style scoped>

</style>