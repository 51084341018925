<template>
  <div class="bg-light rounded-3 py-4 px-md-4 mb-3">
    <div class="steps pt-4 pb-1">
      <div v-for="(stage, i) in stages" :key="i" class="step" :class="{ 'active': isStageActive(i) }">
        <div class="step-progress">
          <span class="step-progress-start"></span><span class="step-progress-end"></span><a href="#"
            @click.prevent="attemptSetWizardStage(i)" class="step-number">{{ i + 1 }}</a>
        </div>
        <div class="step-label"><a @click.prevent="attemptSetWizardStage(i)" href="#">{{ stage }}</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      stages: [
        'Profession',
        'Speciality',
        'Location',
        // 'Charges',
        'Review Results',
        'Job Details',
        'Review'
      ]
    }
  },
  methods: {
    ...mapActions('findALawyer', {
      setWizardStage: 'setWizardStage',
      setIsLocationImportant: 'setIsLocationImportant',
      setSpeciality: 'setSpeciality',
      setSubspecialities: 'setSubspecialities',
    }),

    isStageActive(i) {
      return i <= this.currentStage
    },

    attemptSetWizardStage(i) {
      if (i < this.currentStage) {
        // if (i === 1) {
        if (i == 1) {
          this.setSpeciality(null);
          this.setSubspecialities([]);
        }
        if (i === 2) {
          if (this.isLocationImportant === false) {
            this.setIsLocationImportant(null);
          }
        }
        this.setWizardStage(i);
      } else {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters('findALawyer', {
      currentStage: 'currentStage',
      isLocationImportant: 'isLocationImportant'
    })
  }
};
</script>

<style scoped>
.step-number {
  text-decoration: none;
}

.step-label a {
  color: #666276;
  text-decoration: none;
}

.step.active .step-number {
  background-color: rgb(var(--primary-colour));
}

.step.active .step-progress .step-progress-start,
.step.active .step-progress .step-progress-end {
  background-color: rgb(var(--primary-colour));
}
</style>